import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as HashRouter, Route, Router } from "react-router-dom";
//import Dashboard from './Dashboard';

import logo from './logo.svg';
import './App.css';

console.log('meow');

function App() {
  return (
  <HashRouter>
    <div>
      <div>heyo</div>
        <Route path="/dashboard">
          <div>dashboard</div>
        </Route>
        <Route path="/documentation">
        <div>documentation</div>
        </Route>
    </div>
  </HashRouter>
  );
}

export default App;
